/* globalStyles */

.ant-table-selection-col,
.ant-table-selection-column {
  display: none;
}

.ant-modal-mask {
  backdrop-filter: blur(10px);
}

.ant-input-clear-icon-hidden {
  visibility: visible;
}

.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  height: 5vh !important;
  background: #e2e8ec !important;
  font-size: 18px;
  font-weight: 600;
}

.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  background: #e2e8ec !important;
  border-radius: 5px 0 0 5px !important;
}

.ant-input-group-addon {
  width: 60px !important;
  border: 1px solid #cfd9e0 !important;
  border-radius: 5px !important;
  font-size: 24px !important;
}

.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 26px;
  padding-top: 5px;
  color: #000000;
}

/* Selling Products Table */

.ant-table-thead > tr > th {
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  background: #ebeded;
}

.ant-table-tbody > tr > td {
  font-size: 14px;
  font-weight: 600;
  color: #000000;
}

.ant-table-tbody > tr:hover {
  background: none;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #ebeded;
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #ebeded;
}

.ant-table-tbody > tr > td {
  border: none;
}

/* .ant-table-body{
  width: 100%;
  overflow-y: hidden!important;
}

.ant-table-body:hover{
  overflow-y: scroll!important;
} */

/* Ends */

/* Product Category List Menu */

.ant-menu-item,
.ant-menu-item-only-child {
  color: #607d8b;
  font-size: 16px;
}

.ant-menu-item-only-child:hover {
  color: #607d8b;
}

.ant-menu-item-selected {
  color: #374957;
  font-weight: 600;
}

.ant-menu-item-selected:hover {
  color: #374957;
  font-weight: 600;
}

.ant-menu-submenu-title {
  color: #000000;
  font-weight: 18px;
}

.ant-menu-inline .ant-menu-item {
  margin-top: 0;
}

.ant-menu-sub {
  max-height: 79vh;
  min-height: 76vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.ant-menu-sub::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

/* Ends */

/* Order History Tabs */

.ant-input-prefix {
  margin-right: 20px;
}

.ant-tabs-tab {
  margin: 0 100px;
  font-size: 14px;
  font-weight: 600;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-tab:hover {
  color: #000000;
  font-weight: 600;
}

.ant-tabs-ink-bar,
.ant-tabs-ink-bar-animated {
  width: 100%;
  height: 3px;
  border-radius: 10px;
  background: #0c4da9;
}

/* Ends */

/* Returns Modal */

.ant-modal-title {
  margin: 0;
  color: #0c4da9;
  font-weight: 600;
  font-size: 18px;
}

.ant-modal-close-x {
  font-size: 20px;
  color: #eb3a4c;
}

/* Ends */

/* Attributes Select Box */

.ant-select-item {
  padding: 16px;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.ant-select-selector:hover,
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}

.ant-select-selector {
  height: 7vh !important;
}

.ant-select-selection-item {
  padding-top: 10px !important;
  padding-left: 15px !important;
  font-size: 16px !important;
  color: #000000 !important;
}

.ant-select-arrow {
  top: 22px;
  right: 25px;
  font-size: 16px;
  color: #000000;
}

/* Ends */
